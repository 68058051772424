var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.validator)?_c('v-container',{staticClass:"pt-14 pb-10 d-flex flex-row justify-center"},[_c('v-card',{attrs:{"elevation":"0","width":"720px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-1"},[_vm._v(" Confirm your stake ")])])],1),_c('h1',{staticClass:"headline mt-12 mb-6",class:_vm.$vuetify.breakpoint.mobile ? 'text-center' : ''},[_vm._v("Amount")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3","offset-sm":"0"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('p',{class:_vm.$vuetify.breakpoint.mobile ? 'text-center' : 'overline pb-0 mb-0'},[_vm._v(" Stake amount ")]),_c('span',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'},[_c('div',{staticClass:"d-flex align-center"},[_c('cudos-token',{staticClass:"mr-2",staticStyle:{"width":"25px"}}),_c('p',{staticClass:"ma-0 title",domProps:{"innerHTML":_vm._s(_vm.$utils.splitAmount(_vm.userStake))}})],1)])])],1)],1)],1),_c('h1',{staticClass:"headline mt-12 mb-6",class:_vm.$vuetify.breakpoint.mobile ? 'text-center' : ''},[_vm._v("Validator")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Validator ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Status "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","dark":"","size":"16px"},domProps:{"textContent":_vm._s('mdi-information-outline')}},'v-icon',attrs,false),on))]}}],null,false,1411754205)},[_c('span',[_vm._v("Whether a Validator is currently active in staking or has left and is waiting in their unbonding period")])])],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" Fee (%) "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","dark":"","size":"16px"},domProps:{"textContent":_vm._s('mdi-information-outline')}},'v-icon',attrs,false),on))]}}],null,false,1411754205)},[_c('span',[_vm._v("Commission the Validator will earn on your stake")])])],1)])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('h4',{staticClass:"subtitle-1 mb-1",staticStyle:{"letter-spacing":"0rem !important"}},[_c('span',{staticClass:"overline",staticStyle:{"letter-spacing":"0rem !important"}},[_vm._v(" "+_vm._s(_vm.validator.serviceProviderManager))])]),_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.currentlyStaked(_vm.validator.id) > 0)?[_c('span',[_vm._v("Currently staked:")]),_c('cudos-token',{staticClass:"mx-2",staticStyle:{"width":"25px"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$utils.splitAmount(_vm.currentlyStaked(_vm.validator.id).toString()))}})]:_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v("You haven't staked here yet")])],2)]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{class:{'success--text': !_vm.validator.exited && _vm.validator.isServiceProviderActive, 'error--text': !(!_vm.validator.exited && _vm.validator.isServiceProviderActive)},domProps:{"textContent":_vm._s(!_vm.validator.exited && _vm.validator.isServiceProviderActive ? 'Active' : 'Inactive')}})]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{domProps:{"innerHTML":_vm._s((((_vm.validator.rewardsFeePercentage / 100).toFixed(1)) + "%"))}})]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}})],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-14"},[_c('v-card',{staticClass:"warning-message-container"},[_c('v-col',[_c('v-col',{staticClass:"mt-2",style:({
              paddingLeft: '50px',
              position: 'relative',
            })},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue"},domProps:{"textContent":_vm._s('$warningIcon')}}),_c('span',{style:({ fontWeight: '700' })},[_vm._v("Note")])],1),_c('v-col',{staticClass:"d-flex justify-center warning-message pt-0",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"paddingright":"50px"}},[_vm._v("Confirm button consists of two actions. First you must confirm “Approve” transaction, with which you are approving that your tokens can be taken from your wallet and sent to the contract. The actual “Stake” transaction comes after that and it also needs confirming. ")]),_c('v-btn',{staticClass:"my-6 mx-5",attrs:{"x-large":"","rounded":"","depressed":"","color":"primary"},domProps:{"textContent":_vm._s('Confirm')},on:{"click":_vm.delegateStake}})],1)],1)],1)],1)],1),_c('transaction-indicator',{ref:"transactionIndicator",attrs:{"transaction-function":_vm.delegateStakeFunction}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }