

































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Mutation, Getter } from 'vuex-class'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'
@Component({
  name: 'StakeConfirm',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class SuccessTemplateStakeConfirm extends Vue {
  @Mutation('populateDelegations', { namespace: 'wallet' }) populateDelegations!: any
  @Mutation('populateValidators', { namespace: 'wallet' }) populateValidators!: any
  @Mutation('populatePendingRewards', { namespace: 'wallet' }) populatePendingRewards!: any
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any

  amount = ''
  validatorId = ''
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []
  validator: any = undefined

  async mounted (): Promise<void> {
    if (!this.$route.params.amount) {
      await this.$router.push('/wallet')
    }
    this.amount = this.$route.params.amount
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.validatorId = this.$route.params.validatorId
    const validators = await this.allActiveValidators
    this.validator = validators.filter((validator:any) => { if (validator.serviceProviderManager === this.validatorId) { return validator } })
    this.validator = this.validator[0]
    this.populateDelegations()
    this.populateValidators()
    this.populatePendingRewards()
    this.loaded = true
  }
}
