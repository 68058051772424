












































































import { Component, Vue, Watch } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Getter, Mutation } from 'vuex-class'
import ValidatorSelector from '@/components/ValidatorSelector.vue'
import { FixedNumber } from 'ethers'

@Component({
  name: 'Stake',
  components: {
    CudosToken,
    ValidatorSelector
  }
})
export default class Stake extends Vue {
  @Getter('cudosBalance', { namespace: 'wallet' }) cudosBalance!: number
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Mutation('setUserStake', { namespace: 'wallet' }) setUserStake!: any
  @Getter('delegationsPerValidator', { namespace: 'wallet' }) delegationsPerValidator!: any
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any

  amount = '0'
  min = '0.001'
  max = '0.000'
  increment = '100'
  validators = []
  programmes: any = {}
  delegations: any = {}
  selected: any = null

  valid = true

  rules = [
    (v: string): boolean | string => !!v || 'Amount is required',
    (v: string): boolean | string => (v && this.isNumber(v)) || 'Amount must be a number',
    (v: string): boolean | string => (v && this.checkMax(v)) || 'You don\'t have sufficient balance',
    (v: string): boolean | string => (v && this.checkMin(v)) || 'You must stake a minimum amount'
  ]

  validatorDisabled (validator: any): boolean {
    return !(!validator.exited && validator.isServiceProviderActive)
  }

  beforeMount (): void {
    this.max = FixedNumber.from(this.cudosBalance).toString()
  }

  isNumber (v: string) {
    return !isNaN(v as any) && !isNaN(parseFloat(v))
  }

  async mounted (): Promise<void> {
    await Promise.all([await this.allActiveValidators, await this.programmesPerIndex, await this.delegationsPerValidator])
    this.programmes = await this.programmesPerIndex
    const validators = await this.allActiveValidators
    this.validators = validators.filter(
      (validator: any) => {
        if (!validator.exited) {
          return validator
        }
      })
    this.delegations = await this.delegationsPerValidator
  }

  validatorExtraColumnDataFunction (validator: any): string {
    return `${(validator.rewardsFeePercentage / 100).toFixed(1)}%`
  }

  @Watch('amount')
  handler (): void {
    this.validate()
  }

  checkMax (val: string): boolean {
    const valMax = FixedNumber.from(val).subUnsafe(FixedNumber.from(this.max))
    return !isNaN(val as any) && (valMax.isNegative() || valMax.isZero())
  }

  checkMin (val: string): boolean {
    const valMin = FixedNumber.from(this.min).subUnsafe(FixedNumber.from(val))
    return !isNaN(val as any) && (valMin.isNegative() || valMin.isZero())
  }

  validate (): boolean {
    const form = this.$refs.form as HTMLFormElement
    return form.validate()
  }

  handleAmountChange (direction?: string): void {
    if (direction === 'down') {
      const newAmount = FixedNumber.from(this.amount).subUnsafe(FixedNumber.from(this.increment))
      const minAvail = FixedNumber.from(this.min).subUnsafe(newAmount)
      if (minAvail.isNegative() || minAvail.isZero()) {
        this.amount = newAmount.toString()
      } else {
        this.amount = this.min
      }
    } else if (direction === 'up') {
      const newAmount = FixedNumber.from(this.amount).addUnsafe(FixedNumber.from(this.increment))
      const maxAvail = newAmount.subUnsafe(FixedNumber.from(this.max))
      if (maxAvail.isNegative() || maxAvail.isZero()) {
        this.amount = newAmount.toString()
      } else {
        this.amount = this.max
      }
    } else if (direction === 'max') {
      this.amount = this.max
    }
  }

  async confirm (): Promise<void> {
    if (this.validate()) {
      await this.setUserStake(this.amount)
      await this.$router.push({ name: 'stakeConfirm', params: { validatorId: this.selected.id } })
    }
  }

  async submit (validator: any): Promise<void> {
    this.selected = validator
  }
}
