





































































import { Component, Vue, Watch } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { PendingReward } from '@/modules/wallet/store'
import { ethers, FixedNumber } from 'ethers'
import ValidatorSelector from '@/components/ValidatorSelector.vue'

@Component({
  name: 'StakingWithdraw',
  components: {
    CudosToken, ValidatorSelector
  }
})
export default class StakingWithdraw extends Vue {
  @Getter('userAccount', { namespace: 'wallet' }) userAccount!: string
  @Getter('delegationsPerValidator', { namespace: 'wallet' }) delegationsPerValidator!: any
  @Getter('allPendingRewards', { namespace: 'wallet' }) allPendingRewards!: Promise<PendingReward[]>
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any
  @Getter('numberOfBlocksInADay', { namespace: 'wallet' }) numberOfBlocksInADay!: number
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any

  loading = true
  selected: null | string = null
  amount: any = '0'
  min = '0.0001'
  max: any = '0.000'
  increment = '100'
  validators: any = []
  programmes: any = {}
  delegations: any = {}
  pendingRewards: PendingReward[] = []
  gasEstimate: string | undefined = '-'
  valid = true

  rules = [
    (v: string): boolean | string => !!v || 'You must withdraw a minimum amount',
    (): boolean | string => this.checkSelected() || 'You must select a validator',
    (v: string): boolean | string => (v && this.isNumber(v)) || 'Amount must be a number',
    (v: string): boolean | string => (v && this.checkMax(v)) || 'You don\'t have sufficient stake',
    (v: string): boolean | string => (v && this.checkMin(v)) || 'You cannot withdraw this amount'
  ]

  isNumber (v: string) {
    return !isNaN(v as any) && !isNaN(parseFloat(v))
  }

  validatorDisabled (validator: any): any {
    return false
  }

  checkSelected () {
    return this.selected !== null
  }

  async submit (validator: any) {
    const validatorId = validator.id
    this.selected = validatorId
    if (this.delegations[validatorId]) {
      this.max = ethers.utils.formatEther(this.delegations[validatorId].delegatedStake)
      if (parseFloat(this.amount) > parseFloat(this.max)) {
        this.amount = this.max
      }
    } else {
      this.max = this.amount = 0
    }
  }

  async confirm (): Promise<void> {
    if (this.validate()) {
      await this.$router.push({
        name: 'stakingWithdrawConfirm',
        params: {
          amountWithdrawal: this.amount,
          validatorId: this.selected as string
        }
      })
    }
  }

  async mounted (): Promise<void> {
    await Promise.all([await this.allActiveValidators, await this.programmesPerIndex, await this.delegationsPerValidator])
    this.pendingRewards = await this.allPendingRewards
    this.programmes = await this.programmesPerIndex
    this.delegations = await this.delegationsPerValidator
    this.validators = this.filterValidators(await this.allActiveValidators)
    this.loading = false
  }

  filterValidators (allValidators: any[]) {
    return allValidators.filter((val: any) => {
      return this.currentlyStaked(val.id) !== 0 && !val.exited
    })
  }

  currentlyStaked (validatorId: any) {
    if (this.delegations[validatorId]) {
      return parseFloat(ethers.utils.formatEther(this.delegations[validatorId].delegatedStake))
    } else {
      return 0
    }
  }

  validatorExtraRowDataFunction (validator: any): string {
    return `${this.formatEther(this.pendingReward(validator.id))}`
  }

  pendingReward (validatorId: string): string {
    const pendingReward = this.pendingRewards.filter((pr: PendingReward) => pr.serviceProvider === validatorId).pop()
    if (pendingReward) {
      return pendingReward.reward
    } else {
      return '0'
    }
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }

  // Unfinished and untested
  async withdrawRequest (): Promise<void> {
    if (!this.validate()) {
      return
    }

    await this.$router.push({
      name: 'stakingWithdrawConfirm',
      params: {
        amountWithdrawal: this.amount.toString(),
        validatorId: this.selected as string
      }
    })
  }

  @Watch('amount')
  handler (): void {
    this.validate()
  }

  validate (): boolean {
    const form = this.$refs.form as HTMLFormElement
    const validate = form.validate()
    this.valid = validate
    return validate
  }

  checkMax (val: string): boolean {
    const valMax = FixedNumber.from(val).subUnsafe(FixedNumber.from(this.max))
    return !isNaN(val as any) && (valMax.isNegative() || valMax.isZero())
  }

  checkMin (val: string): boolean {
    const valMin = FixedNumber.from(this.min).subUnsafe(FixedNumber.from(val))
    return !isNaN(val as any) && (valMin.isNegative() || valMin.isZero())
  }

  formatDays (blocks: any) {
    const days = Math.ceil(blocks / this.numberOfBlocksInADay)
    const months = Math.floor(days / 30)
    if (months === 0) {
      return `${days} Days`
    } else {
      const remainingDays = days - months * 30
      return `${months} Months, ${remainingDays} Days`
    }
  }

  handleAmountChange (direction?: string): void {
    if (direction === 'down') {
      const newAmount = FixedNumber.from(this.amount).subUnsafe(FixedNumber.from(this.increment))
      const minAvail = FixedNumber.from(this.min).subUnsafe(newAmount)
      if (minAvail.isNegative() || minAvail.isZero()) {
        this.amount = newAmount.toString()
      } else {
        this.amount = this.min
      }
    } else if (direction === 'up') {
      const newAmount = FixedNumber.from(this.amount).addUnsafe(FixedNumber.from(this.increment))
      const maxAvail = newAmount.subUnsafe(FixedNumber.from(this.max))
      if (maxAvail.isNegative() || maxAvail.isZero()) {
        this.amount = newAmount.toString()
      } else {
        this.amount = this.max
      }
    } else if (direction === 'max') {
      this.amount = this.max
    }
  }
}
