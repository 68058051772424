



























































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { Delegation, PendingReward } from '@/modules/wallet/store'
import { BigNumber, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
import ValidatorSelector from '@/components/ValidatorSelector.vue'

@Component({
  name: 'StakingWithdraw',
  components: {
    CudosToken, TransactionIndicator, ValidatorSelector
  }
})
export default class StakingWithdrawExitValidator extends Vue {
  @Getter('allPendingRewards', { namespace: 'wallet' }) allPendingRewards!: Promise<PendingReward[]>
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Getter('allDelegations', { namespace: 'wallet' }) allDelegations!: Promise<Delegation[]>
  @Getter('programmesPerIndex', { namespace: 'wallet' }) programmesPerIndex!: any
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any

  loading = true
  selected: any = null
  validators = []
  pendingRewards: PendingReward[] = []
  delegations: Delegation[] = []
  programmes: any = {}
  gasEstimate: string | undefined = '-'
  gasCost: string | undefined = '-'
  availableRewards = BigNumber.from(0)

  async mounted (): Promise<void> {
    await Promise.all([this.allActiveValidators, this.allDelegations, this.allPendingRewards, await this.programmesPerIndex])
    this.delegations = await this.allDelegations
    this.pendingRewards = await this.allPendingRewards
    this.validators = await this.allActiveValidators
    this.programmes = await this.programmesPerIndex
    this.calculateAvailableRewards(this.pendingRewards)
    this.loading = false
  }

  calculateAvailableRewards (pendingRewards: PendingReward[]) {
    pendingRewards.forEach(reward => {
      this.availableRewards = this.availableRewards.add(reward.reward)
    })
  }

  currentlyStaked (validatorId: any) {
    const index = this.delegations.findIndex((del: Delegation) => del.serviceProvider === validatorId)
    if (index !== -1) {
      return parseFloat(ethers.utils.formatEther(this.delegations[index].delegatedStake))
    } else {
      return 0
    }
  }

  get validatorItems () {
    return this.validators.filter((validator: any) => {
      if (this.isExitable(validator)) {
        return validator
      }
    })
  }

  isExitable (validator: any): boolean {
    return validator.exited && this.currentlyStaked(validator.id) !== 0
  }

  validatorExtraColumnDataFunction (validator: any): string {
    return `${(validator.rewardsFeePercentage / 100).toFixed(1)}%`
  }

  validatorExtraRowDataFunction (validator: any): string {
    return `${this.formatEther(this.pendingReward(validator.id))}`
  }

  pendingReward (validatorId: string): string {
    const pendingReward = this.pendingRewards.filter((pr: PendingReward) => pr.serviceProvider === validatorId).pop()
    if (pendingReward) {
      return pendingReward.reward
    } else {
      return '0'
    }
  }

  validatorDisabled (validator: any): boolean {
    return !this.isExitable(validator)
  }

  async submit (validator: any) {
    this.selected = validator
    await this.estimatedGas(validator.id)
  }

  async estimatedGas (validatorId: string): Promise<void> {
    const serviceProviderContract = await this.connectToServiceProvider(validatorId)
    const fee = await serviceProviderContract.estimateGas.exitAsDelegator()
    this.gasEstimate = ethers.utils.formatEther(fee)
    this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
  }

  async exitValidtor () {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  async exitValidatorFunction (): Promise<void> {
    const hashes = []
    const serviceProviderContract = await this.connectToServiceProvider(this.selected.id)
    const tx = await serviceProviderContract.exitAsDelegator()
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({
      name: 'stakingWithdrawExitValidatorSuccess',
      params: {
        amount: this.currentlyStaked(this.selected.id).toString(),
        validatorId: this.selected.serviceProviderManager as string,
        hashes: hashes.join(',')
      }
    })
  }

  formatEther (gwei: any) {
    return ethers.utils.formatEther(gwei)
  }
}
