






























import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { ethers } from 'ethers'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'
@Component({
  name: 'StakingWithdrawExitValidatorSuccess',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class
SuccessTemplateStakingWithdrawExitValidatorSuccess extends Vue {
  amount = ''
  validatorId = ''
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []

  async mounted (): Promise<void> {
    if (!this.$route.params.amount) {
      await this.$router.push('/staking')
    }
    const tokens = ethers.utils.parseEther(this.$route.params.amount.toString())
    this.amount = ethers.utils.formatEther(tokens)
    this.validatorId = this.$route.params.validatorId
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.loaded = true
  }
}
