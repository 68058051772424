




































































































































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { BigNumber, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
import { app } from 'bnc-onboard/dist/src/stores'

@Component({
  name: 'StakeConfirm',
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class StakeConfirm extends Vue {
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any
  @Getter('delegationsPerValidator', { namespace: 'wallet' }) delegationsPerValidator!: any
  @Getter('cudosBalance', { namespace: 'wallet' }) cudosBalance!: number
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('newUserStake', { namespace: 'wallet' }) userStake!: number
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any

  validator: any = {}
  loading = true
  stakeAmount: number | undefined
  validatorId = ''
  gasEstimate = '-'
  gasCost = '-'
  serviceProviderContract: any
  delegations = ''

  async mounted (): Promise<void> {
    try {
      this.stakeAmount = this.userStake
      if (!this.stakeAmount || !this.$route.params.validatorId) {
        await this.$router.push('/stake')
      }
      this.validatorId = this.$route.params.validatorId
      const validators = await this.allActiveValidators
      this.validator = validators.filter((validator:any) => { if (validator.id === this.validatorId) { return validator } })
      this.validator = this.validator[0]
      this.serviceProviderContract = await this.connectToServiceProvider(this.validatorId)
      this.delegations = await this.delegationsPerValidator
      await this.estimatedGas()
      this.loading = false
    } catch (err) {
      console.log(err)
    }
  }

  async estimatedGas (): Promise<void> {
    const {
      cudosToken
    } = this.allContracts

    try {
      const tokens = ethers.utils.parseEther(this.stakeAmount?.toString() as string)
      let fee = await cudosToken.estimateGas.approve(await this.serviceProviderContract?.controller(), tokens)
      const delegateEstimate = 200_000 + parseInt((Math.random() * 20_000).toString())
      fee = fee.add(BigNumber.from(delegateEstimate))
      // Estimate gas cost for delegating stake here
      // const fee2 = await serviceProviderContract.estimateGas.delegateStake(this.stakeAmount)
      this.gasEstimate = ethers.utils.formatEther(fee)
      this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
    } catch (e) {
      console.log(e)
    }
  }

  async delegateStakeFunction (): Promise<void> {
    const {
      cudosToken
    } = this.allContracts
    const hashes = []
    const tokens = ethers.utils.parseEther(this.stakeAmount?.toString() as string)
    const approvedTokens = localStorage.getItem('approvedTokens')
    let shouldApprove = true
    let parsedAllowance: any = {}
    const controllerAddress = await this.serviceProviderContract?.controller()

    try {
      if (approvedTokens) {
        parsedAllowance = JSON.parse(approvedTokens)
      }
      if (approvedTokens && parsedAllowance[controllerAddress] &&
        ethers.utils.parseEther(parsedAllowance[controllerAddress]).gte(tokens)) {
        shouldApprove = false
      }
    } catch (e) {
      console.error('error while parsing approved tokens, will reset them')
      localStorage.setItem('approvedTokens', '{}')
    }
    let tx
    if (shouldApprove) {
      tx = await cudosToken.approve(controllerAddress, tokens)
      hashes.push(tx.hash)
      await tx.wait()
      parsedAllowance[controllerAddress] = this.stakeAmount?.toString() as string
      localStorage.setItem('approvedTokens', JSON.stringify(parsedAllowance))
    }

    tx = await this.serviceProviderContract?.delegateStake(tokens)
    hashes.push(tx.hash)
    await tx.wait()
    parsedAllowance[controllerAddress] = ''
    localStorage.setItem('approvedTokens', JSON.stringify(parsedAllowance))
    await this.$router.push({
      name: 'stakeSuccess',
      params: {
        amount: this.stakeAmount?.toString() as string,
        validatorId: this.validator.serviceProviderManager,
        hashes: hashes.join(',')
      }
    })
  }

  currentlyStaked (validatorId: any) {
    if (this.delegations[validatorId]) {
      // @ts-ignore
      return parseFloat(ethers.utils.formatEther(this.delegations[validatorId].delegatedStake))
    } else {
      return '0'
    }
  }

  async delegateStake (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }
}
