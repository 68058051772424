

































































































































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Action, Getter } from 'vuex-class'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import TransactionIndicator from '@/components/TransactionIndicator.vue'
import { formatUnits } from '@ethersproject/units/src.ts/index'
import { formatGasPrice } from '@/plugins/format'

@Component({
  name: 'StakingWithdrawConfirm',
  components: {
    CudosToken, TransactionIndicator
  }
})
export default class StakingWithdrawConfirm extends Vue {
  @Action('connectToServiceProvider', { namespace: 'wallet' }) connectToServiceProvider!: any
  @Getter('cudosBalance', { namespace: 'wallet' }) cudosBalance!: number
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Getter('delegationsPerValidator', { namespace: 'wallet' }) delegationsPerValidator!: any

  loading = true
  gasEstimate: any = '-'
  gasCost: any = '-'
  validatorId = ''
  rewardsWithdrawal = ''
  validator = []
  delegations = ''

  async mounted (): Promise<void> {
    if (!this.$route.params.amountWithdrawal || !this.$route.params.validatorId) {
      await this.$router.push('/stake/withdraw')
    }
    this.rewardsWithdrawal = this.$route.params.amountWithdrawal.toString()
    this.validatorId = this.$route.params.validatorId
    this.delegations = await this.delegationsPerValidator
    const validators = await this.allActiveValidators
    this.validator = validators.filter((validator:any) => { if (validator.id === this.validatorId) { return validator } })
    this.validator = this.validator[0]
    await this.estimatedGas(this.validatorId, this.rewardsWithdrawal)
    this.loading = false
  }

  // Estimated gas functions are duplicated for the most part. Can make it a shared
  // function to reduce duplication.
  async estimatedGas (validatorId: string, amount: string): Promise<void> {
    if (this.gasEstimate !== '-') {
      return
    }
    const tokens = ethers.utils.parseEther(amount)

    try {
      const serviceProviderContract = await this.connectToServiceProvider(validatorId)
      const fee = await serviceProviderContract.estimateGas.requestDelegatedStakeWithdrawal(tokens)
      this.gasEstimate = ethers.utils.formatEther(fee)
      this.gasCost = (this as any).$utils.formatGasPrice(fee, await (this as any).$services.gasPrices.getAverageGasPrice())
    } catch (e) {
    }
  }

  async withdrawDelegatedStake (): Promise<void> {
    await (this.$refs as any).transactionIndicator.callTransactionFunction()
  }

  currentlyStaked (validatorId: any) {
    if (this.delegations[validatorId]) {
      // @ts-ignore
      return parseFloat(ethers.utils.formatEther(this.delegations[validatorId].delegatedStake))
    } else {
      return '0'
    }
  }

  async withdrawDelegatedStakeFunction (): Promise<void> {
    const tokens = ethers.utils.parseEther(this.rewardsWithdrawal.toString())
    const hashes = []
    const serviceProviderContract = await this.connectToServiceProvider(this.validatorId)
    const gasLimit = ethers.utils.parseEther(this.gasEstimate).mul(2)
    const tx = await serviceProviderContract.requestDelegatedStakeWithdrawal(tokens, { gasLimit: gasLimit })
    hashes.push(tx.hash)
    await tx.wait()

    await this.$router.push({
      name: 'stakingWithdrawSuccess',
      params: { amount: this.rewardsWithdrawal, hashes: hashes.join(',') }
    })
  }
}
