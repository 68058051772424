



























import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { ethers } from 'ethers'
import { Mutation } from 'vuex-class'
import { VARIABLES } from '@/variables'
import SuccessTemplate from '@/components/SuccessTemplate.vue'
@Component({
  name: 'StakeConfirm',
  components: {
    CudosToken,
    SuccessTemplate
  }
})
export default class
SuccessTemplateStakeConfirm extends Vue {
  @Mutation('populateDelegations', { namespace: 'wallet' }) populateDelegations!: any
  @Mutation('populateValidators', { namespace: 'wallet' }) populateValidators!: any
  @Mutation('populatePendingRewards', { namespace: 'wallet' }) populatePendingRewards!: any

  amount = ''
  url = VARIABLES.ETHERSCAN_URL
  loaded = false
  hashes: any[] = []

  async mounted (): Promise<void> {
    if (!this.$route.params.amount) {
      await this.$router.push('/staking')
    }
    const tokens = ethers.utils.parseEther(this.$route.params.amount.toString())
    this.amount = ethers.utils.formatEther(tokens)
    if (this.$route.params && this.$route.params.hashes) {
      this.hashes = this.$route.params.hashes.split(',')
    }
    this.loaded = true
    this.populateDelegations()
    this.populateValidators()
    this.populatePendingRewards()
  }

  get subtitle (): string {
    return 'Please allow 21 days for your CUDOS tokens to be released'
  }
}
