























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import CudosToken from '@/components/CudosToken.vue'
import { Getter, State } from 'vuex-class'
import { BigNumber, ethers } from 'ethers'
import { Delegation, PendingReward } from '@/modules/wallet/store'

const namespace = 'wallet'

@Component({
  name: 'stake',
  components: {
    CudosToken
  }
})
export default class Staking extends Vue {
  @Getter('userAccount', { namespace: 'wallet' }) userAccount!: any
  @Getter('allContracts', { namespace: 'wallet' }) allContracts!: any
  @Getter('blockNumber', { namespace: 'wallet' }) blockNumber!: any
  @Getter('allActiveValidators', { namespace: 'wallet' }) allActiveValidators!: any
  @Getter('allDelegations', { namespace: 'wallet' }) allDelegations!: Promise<Delegation[]>
  @Getter('allPendingRewards', { namespace: 'wallet' }) allPendingRewards!: Promise<PendingReward[]>
  @Getter('cudosBalance', { namespace }) cudosBalance!: number
  @Getter('deprecatedPendingRewards', { namespace }) deprecatedPendingRewards!: number
  @State('userStakeData', { namespace }) userStakeData!: any
  @State('loaded', { namespace: 'wallet' }) loaded!: boolean
  @State('isStakingPaused', { namespace: 'wallet' }) isStakingPaused!: boolean

  loading = true
  validators = []
  currentBlock = Infinity
  totalAmountStaked = BigNumber.from(0)
  pendingRelease = BigNumber.from(0)
  availableForWithdraw = BigNumber.from(0)
  availableRewards = BigNumber.from(0)
  delegations: Delegation[] = []
  showExit = false
  isContractPaused = false

  async mounted (): Promise<void> {
    await Promise.all([this.allActiveValidators, this.allDelegations, this.blockNumber, this.allPendingRewards])
    this.validators = await this.allActiveValidators
    this.currentBlock = await this.blockNumber
    this.delegations = await this.allDelegations
    this.calculateParams(await this.allDelegations, await this.blockNumber)
    this.calculateAvailableRewards(await this.allPendingRewards)
    this.isAnyValidatorExitable(this.validators)
    this.loading = false
    await this.$store.dispatch('wallet/getIsStakingContractPaused')
    this.isContractPaused = this.isStakingPaused
  }

  calculateParams (delegations: Delegation[], currentBlock: number) {
    delegations.forEach((delegation: Delegation) => {
      this.totalAmountStaked = this.totalAmountStaked.add(BigNumber.from(delegation.delegatedStake))
      if (delegation.withdrawalRequested) {
        if (currentBlock > delegation.withdrawalPermittedFrom) {
          this.availableForWithdraw = this.availableForWithdraw.add(BigNumber.from(delegation.withdrawalRequestAmount))
        } else {
          this.pendingRelease = this.pendingRelease.add(BigNumber.from(delegation.withdrawalRequestAmount))
        }
      }
    })
  }

  calculateAvailableRewards (pendingRewards: PendingReward[]) {
    pendingRewards.forEach(reward => {
      this.availableRewards = this.availableRewards.add(reward.reward)
    })
  }

  // Potentially broken
  isAnyValidatorExitable (validators: any[]): void {
    this.showExit = validators.some((validator: any) => {
      return validator.exited && parseFloat(this.currentlyStaked(validator.id)) !== 0
    })
  }

  currentlyStaked (validatorId: any) {
    const index = this.delegations.findIndex((del: Delegation) => del.serviceProvider === validatorId)
    if (index !== -1) {
      return this.formatEther(this.delegations[index].delegatedStake)
    } else {
      return '0'
    }
  }

  formatEther (gwei: any, mobile = false) {
    const formated = ethers.utils.formatEther(gwei)
    if (mobile && formated.length > 11) {
      return formated.slice(0, 11) + '...'
    } else {
      return formated
    }
  }
}
